body {
  font-family: "Source Sans 3", sans-serif;
  background-color: #050217;
  color: #ffffff;
}

.header {
  font-size: 27px;
}

.container {
  min-height: 100vh;
  margin-right: 348px;
  font-size: 27px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 30px;
  padding-right: 162px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.main {
  margin-top: 190px;
  margin-bottom: 90px;
}

.container__collection {
  padding-right: 30px;
}

.container__collection .main {
  display: flex;
  flex-wrap: wrap;
}

.graph {
  margin-top: 20px;
  margin-bottom: 20px;
}

.collection__item {
  margin-top: 30px;
}

.collection__title {
  padding: 20px;
}

.token__item {
  background-color: #ffffff;
  max-width: 645px;
  max-height: 645px;
  width: calc(100vw - 400px);
  height: calc(100vw - 400px);
  border-width: 1px;
  border-style: solid;
  border-color: #82808b;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* cursor: pointer; */
}

.token__preview {
  width: 200px;
  height: 200px;
  border-width: 1px;
  border-style: solid;
  border-color: #82808b;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.token__preview span {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 22.02px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
}

.token__preview:hover {
  /* background-color: #ffffff; */
  border-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background-image: var(--hover-image); */
  cursor: pointer;
}

.token__preview:hover span {
  /* border-color: #ffffff; */
  opacity: 0.9;
  color: #ffffff;
}

.token__arrows {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  align-content: stretch;
  justify-content: space-between;
}

.main strong {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 47px;
  color: #ffffff;
  opacity: 0.9;
}

.main h2 {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 47px;
  color: #ffffff;
  opacity: 0.9;
}

.main p {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 38px;
  color: #ffffff;
  opacity: 0.9;
}

.main p strong {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 38px;
  color: #ffffff;
  opacity: 0.9;
}

.main img {
  max-width: 645px;
}

.main a {
  text-decoration: none;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  color: #379ee9;
  opacity: 0.9;
}

.footer {
  font-size: 27px;
}

.footer__links {
  display: flex;
  justify-content: space-between;
  width: 450px;
  margin-bottom: 30px;
}

.footer__links a {
  text-decoration: none;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 38px;
  color: #379ee9;
}

.bottombar {
  display: none;
}

.sidebar {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  width: 259px;
  height: 100vh;
  overflow-x: hidden;
  padding-left: 1px;
  padding-right: 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.sidebar_inner {
  padding-top: 39px;
}

.sidebar_inner_bottom {
  width: 100%;
  padding-bottom: 29px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.connect_wallet {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 38px;
  color: #379EE9;
}

.connect_wallet:hover {
  cursor: pointer;
}

.bottom_logo {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 38px;
  color: #FFFFFF;
}

/* L/W */

/*

font-family: 'Source Sans 3';
font-style: normal;
font-weight: 700;
font-size: 27px;
line-height: 38px;

color: #FFFFFF;

*/

.sidebar a {
  padding: 6px 8px 0px 0px;
  text-decoration: none;
  font-size: 25px;
  display: block;
}

.sidebar__title {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 47px;
  color: #ffffff;
  opacity: 0.9;
}

.sidebar .link {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 31px;
  color: #ffffff;
  opacity: 0.5;
}

.sidebar .link:hover {
  opacity: 0.9;
}

.sidebar .selected {
  opacity: 0.9;
}

@media screen and (max-width: 1000px) {
  .container {
    margin-right: 268px;
    font-size: 27px;
    padding-right: 30px;
  }
  .main {
    margin-top: 30px;
  }
  .main p {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    color: #ffffff;
  }
  .sidebar {
    padding-right: 8px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    margin-right: 0;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 27px;
  }

  .main {
    margin-top: 30px;
    margin-bottom: 137px;
  }

  .main p {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    color: #ffffff;
  }

  .main p strong {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 27px;
    color: #ffffff;
  }

  .main ul, .main ol {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
  }

  .main a {
    text-decoration: none;
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    color: #379ee9;
  }

  .main img {
    max-width: 350px;
  }

  .graph {
    /* display: none; */
  }

  .token__item {
    max-width: 645px;
    max-height: 645px;
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
    border-width: 1px;
    border-style: solid;
    border-color: #82808b;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .token__preview {
    width: 104px;
    height: 104px;
    border-width: 1px;
    border-style: solid;
    border-color: #82808b;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .token__preview span {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    opacity: 0.5;
  }

  .sidebar {
    display: none;
  }
  .footer {
    display: none;
  }
  .bottombar {
    display: block;
    height: 137px;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #050217;
    overflow-x: hidden;
  }

  .opened-menu {
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .bottombar__title {
    margin: 0;
    padding: 0;
    margin-top: 15px;
    margin-left: 15px;
    max-width: 200px;
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    color: #ffffff;
    opacity: 0.9;
  }
  .bottombar__menu {
    display: none;
  }
  .opened-menu .bottombar__menu {
    display: block;
    padding-top: 20px;
  }
  .bottombar__panel {
    display: flex;
    justify-content: space-between;
  }
  .bottombar__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 18px;
    margin-right: 15px;
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: right;
    color: #379ee9;
  }

  .bottombar_logo {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 27px;
    text-align: right;
    color: #FFFFFF;
  }

  .bottombar__menu a {
    padding: 6px 8px 15px 15px;
    text-decoration: none;
    font-size: 25px;
    display: block;
  }

  .bottombar__menu .link {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 31px;
    color: #ffffff;
    opacity: 0.5;
  }

  .bottombar__menu .link:hover {
    opacity: 0.9;
  }

  .bottombar__menu .selected {
    opacity: 0.9;
  }


  
}
